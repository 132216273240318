/* Sandwichs et plats */
.option {
    &::before {
      content: "- ";
    }
    color: white;
    margin-left: 20px;
    margin-bottom: 10px;
    list-style: circle;
  }
  
  /* Sauce au choix */
  .sauce {
    grid-column: span 2;
    // max-width: 250px;
    margin: auto;
    margin-top: 25px;
    // padding: 5px 14px;
  }
  
  /* Fin sauce au choix */
  .sandwich,
  .plats, .specialBurger {
    display: grid;
    text-align: left;
    grid-template-columns: 60% 1fr;
    padding: 2.5rem;
  
    h2 {
      grid-column: span 2;
      margin-bottom: 25px;
    }
  }
  
  .specialBurger{
    grid-column: span 2;
  }

  @media screen and (max-width:1420px){
  .specialBurger{
    grid-column: span 2;
  }
  .cheese{
    grid-column-start: 3;
  }
  .burger{
    grid-column-start: 1 ;
  }
}
@media  screen and (max-width:1065px) {
  .cheese{
    grid-column-start: 2;
  }
  
}
@media screen and (max-width:715px){
  .cheese{
    grid-column-start: 1;
  }
}


  .americain {
    width: 100%;
    max-width: 50rem;
    grid-row-start: 3;
    grid-column: span 2;
  }
  
  @media screen and (max-width: 900px) {
    .americain {
      grid-column: 1;
    }
    .croque {
      grid-row-start: 2;
    }
    .croque,
    .kebab {
      max-width: 50rem;
      width: 100%;
    }
  }

@import "burgers"