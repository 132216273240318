@import "scss/reset";
@font-face {
	font-family: "Segoe Script Regular";
	font-style: normal;
	font-weight: normal;
	src: local("Segoe Script Regular"),
		url("/assets/Segoe\ Script.woff") format("woff");
}
@font-face {
	font-family: "Dancing Script";
	src: url(/assets/DancingScript-VariableFont_wght.ttf) format("truetype");
}
@font-face {
	font-family: "Gill Sans MT Ultra Bold";
	src: url(/assets/Gill-Sans-MT-Ultra-Bold.ttf) format("truetype");
}

$coin: 2.5em;
$text: #333b3d;
$orange: #f5b201;
$dorange: #e8a501;
$bg: #2e2d2d;
$ultrabold: "Gill Sans Mt Ultra Bold", sans-serif;

html {
  background: linear-gradient(to right, rgb(0, 0, 0), rgb(36, 36, 36));

	font-family: "Open Sans", sans-serif;
	font-size: 10px;
	transition: all 0.3s ease-in-out;
}

h1 {
	font-family: "Dancing Script", sans-serif, cursive;
}

h2 {
	font-family: "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
	font-weight: bold;
}

.tout {
	max-width: 1920px;
	margin: auto;
  background-color: rgb(225, 218, 218);
}

header {
	overflow: hidden;
  	background-color: rgb(36, 36, 36);
	background-image: url(../assets/img/hero-bg.jpeg);
	background-size: cover;
	background-position: center;
}
