.title-menu {
    display: inline-block;
    align-self: center;
    position: relative;
    width: 60%;
    max-width: 350px;
    h1 {
      color: white;
      font-weight: bold;
      font-size: clamp(45px, 10vw, 8rem);
    }
  }
  
  .nav-menu {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 4rem;
    .filters_menu {
      width: 100%;
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      padding-bottom: 2%;
      color: white;
      font-size: 3rem;
    }
    button {
      font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
      min-width: 200px;
      text-transform: uppercase;
      padding-bottom: 2em;
    }
    .mixitup-control-active {
      text-decoration: underline $orange;
      -webkit-text-decoration: underline $orange;
      text-underline-offset: 0.3em;
    }
    .button {
      display: flex;
      font-size: 2rem;
    }
  }