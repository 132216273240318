.content {
    display: grid;
    padding: 10% 0;
    // grid-template-columns: 35% 1fr 35%;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    justify-items: center;
    align-items: center;
  }
  @import "horaires";
  @import "button-view-carte";

// Media queri de tout
@media screen and (max-width: 900px) {
    .content {
      display: flex;
      flex-direction: column;
    }
    .vitrine {
      display: none;
    }
    .view-carte{
      align-self: center;
      height: 100px;
      // margin-bottom: 3rem;
      button{
        position: initial;
      }
      img{
        display: none;
      }
    }
  }
  
  @media screen and (max-width:1200px){
      .vitrine{
        grid-row-start: 1;
        padding-bottom: 50px;
      }
  }