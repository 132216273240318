/* PIZZA / BRUSCHETTAS / PANINIS */

.contents {
  display: grid;
  padding: 10em 25px;
  width: 100%;
  max-width: 1700px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 50px;
  justify-items: center;
  position: inherit;
}

h2,
.price {
  color: $orange;
}
.price {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-weight: bold;
  justify-self: flex-end;
}
h2 {
  // font-size: 20px;
  font-family: "Gill Sans MT Ultra Bold";
  text-transform: lowercase;
}
h2:first-letter {
  text-transform: capitalize;
}
.mix {
  display: flex;
  width: 100%;
  max-width: 400px;
  min-height: 17.5rem;
  //   max-width: 500px;
  font-size: 3em;
  flex-direction: column;
  justify-content: space-between;
  background-color: rgba($color: white, $alpha: 0.2);
  padding: 15px;
  border-radius: 10px;
  border: 2px solid white;
}
.description {
  color: white;
  font-size: 0.7em;
}

// PIZZAS Supléments ! et bruschettas
.content-supplement{
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  justify-content: space-around;
  align-content: center;
}
@media screen and (max-width:1215px) {
  .contents{
    padding-top: 15em;
  }
}
@media screen and (max-width:805px) {
  .contents{
    padding-top: 20em;
  }
}
.supplement {
  color: white;
  font-size: 2em;
  border-radius: 5px;
  border-color: $orange;
  width: 95%;
  min-width: 225px;
  min-height: 50px;
  background-color: rgba(152, 152, 152, 0.651);
}
@import "sandwichs";
@import "plats"


