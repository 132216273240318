// Horaires d'ouvertures
.hourly {
  grid-row-start: 1;
  grid-row-end: 3;
  background-color: $bg;
  opacity: 0.9;
  border: 0.2rem solid $dorange;
  border-radius: $coin $coin 0 $coin;
  max-width: 425px;
  min-width: 400px;
  width: 80%;
  // min-height: 60%;
  // height: auto;
  padding: 3.5rem;
  transition: all .5s ease-out;
}
.deco {
  border: 0.1em solid white;
  border-radius: 1em 1em 0 1em;
  color: white;
  text-align: center;
  min-width: 100%;
  height: auto;
  h2 {
    text-transform: uppercase;
    font-family: $ultrabold;
    font-size: 2.9rem;
    // letter-spacing: 0.1vw;
    line-height: 4rem;
  }
  h2:first-child {
    padding-top: 5%;
    // padding-bottom: 0.3em;
  }
  h2 span{
    color: white;
  }
  h3,
  .lundi {
    font-style: normal;
    font-size: 3.5rem;
  }
  h3 {
    padding-top: 5%;
    font-weight: bold;
  }
  p {
    font-size: 2.3rem;
    padding-top: 2%;
    font-style: italic;
    padding-bottom: 2%;
  }
}
@media screen and (max-width:500px) {
  .deco{
    border: none;
    h3, .lundi{
      font-size: 3rem;
    }
    h3{
      padding-top: 2%;
    }
    p{
      padding-bottom: 1%;
    }
    svg{
      min-width: 200px;
    }
  }
  .hourly{
    min-width: 300px;
    padding: 15px;
    opacity: 0.92;
  }
}
// Underline orange h2
.cls-1 {
  fill: #f5b201;
  stroke: #f5b201;
  stroke-width: 1px;
  stroke-dasharray: 6;
}
// Underline-orange pour les titres h3
.cls-2 {
  fill: #f5b201;
  stroke: #f5b201;
  stroke-width: 1px;
  stroke-dasharray: 10;
}

.svg {
  width: 80%;
  margin: 0 auto;
  height: 10px;
  margin-bottom: 3rem;
  display: block;
}

.underline-h1 {
  width: 100%;
}
.underline-h3 {
  width: 60%;
}
/* Fin des horaires d'ouvertures */
