.modal{
    position: fixed;
    z-index: 10000;
    display: flex;
    justify-content: center;
    align-items: center;
    top : 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color : rgba(0, 0, 0, 0.8);
}

.modal-wrapper{
    position: relative;
    overflow: auto;
    width: 90%;
    background-color: $text ;
    border-radius: 2rem;
    max-width: calc(100vw - 50px);
    max-height: calc(100vh - 50px);
}
.modal-header{
    margin: 25px;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: space-around;
    flex-wrap: wrap;
    
}
.modal-content{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 25px;
    margin: 25px;
}

.js-modal-close{
    position: absolute;
    top: 0;
    right: 0;
    color: $orange;
    display: inline;
    font-size: 4rem;
}

.modal-item{
    max-width: 200px;
    max-height: 75px;
    border-radius: 15px;
    border: 2px solid white;
    padding: 15px;
    background-color: rgba(152, 152, 152, 0.651);
    h3{
        color: white;
        font-size: 2rem;
    }
    p{
        font-size: 2rem;
    }
}

