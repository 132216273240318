$color: rgb(255, 255, 255);

.button {
  display: inline-block;
  font-family: "Open Sans", sans-serif;
  text-align: center;
  padding: 1.25rem 2em;
  border-radius: 10rem;
  color: #333b3d;
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: bold;
  letter-spacing: 0.15rem;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $color;
    border-radius: 10rem;
    z-index: -2;
  }
  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: $orange;
    transition: all 0.3s;
    border-radius: 10rem;
    z-index: -1;
  }
  &:hover {
    color: white;
    &:before {
      width: 100%;
    }
  }
}
