.container {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  height: 100%;
  margin-top: 12.5rem;
  position: relative;
  padding-top: 5rem;
  background-position: fixed;
}
@media screen and (max-width:850px){
  .container{
    margin-top: clamp(10vw, 10vw, 20vw);
  }
  
}
@media screen and (min-width:1250px) {
  .container{
    background-image: url(../assets/img/wood.jpeg);
  }
}
@media screen and (max-width:1248px) {
  .container{
    background-image: url(../assets/img/wood-medium.jpeg);
  }
}
@media screen and (max-width:648px) {
  .container{
    background-image: url(../assets/img/wood-mobile.jpeg);
  }
}
@import "Products/products-all";

@import "Buttons/sauce";


