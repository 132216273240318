.navigationCheckbox {
  display: none;
}

.navigationBg {
  height: 15px;
  width: 100vw;
  // border-radius: 50%;
  position: relative;
  top: -50px;
  // background: linear-gradient(to right, rgb(0, 0, 0), rgb(36, 36, 36));
  opacity: 1;
  z-index: 1000;
  transition: height 0.8s cubic-bezier(0.86, 0, 0.07, 1);
}


.navigationBtn {
  // background-color: #fff;
  // height: 7rem;
  // width: 7rem;
  position: absolute;
  top: 0;
  right: 5rem;
  // border-radius: 50%;
  z-index: 2000;
  text-align: center;
  cursor: pointer;
}


.navigationIcon {
  position: absolute;
  margin-top: 3.5rem;
  width: 3rem;
  height: 2px;
  background-color: $dorange;
  display: block;
}

.navigationIcon::before,
.navigationIcon::after {
  width: 3rem;
  height: 2px;
  background-color: $dorange;
  display: inline-block;
  content: "";
  position: absolute;
  left: 0;
  transition: all .2s;
}

.navigationIcon::before {
  top: -.8rem;
}

.navigationIcon::after {
  top: .8rem;
}


.nav {
  position: relative;
  width: 100%;
  height: 0;
  overflow: hidden;
  opacity: 0;
  transition: all 0.8s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  padding-top: 50px;
}


.navigationListe {
  display: grid;
  text-align: center;

  flex-direction: column;
  list-style-type: none;
  padding: 15px;
  // width: 100%;
  height: 100%;
  color: #fff;
}


.navigationItem a {
  display: inline-block;
  margin-top: 1rem;
  font-size: 3rem;
  font-weight: 300;
  color: $dorange;
  text-decoration: none;
  text-transform: uppercase;
  padding: .3em;
  border-radius: 2em;
  background-color: white;
  cursor: pointer;
}
.call-us{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  font-size: 4rem;
  text-transform: uppercase;
  color: $text;
  border-radius: 10px;
  background-color: rgb(186, 185, 185);
  max-height: 150px;
}


.navigationCheckbox:checked ~ .navigationBg {
  height: 500px;
}


.navigationCheckbox:checked ~ .navigationBg .nav {
  opacity: 1;
  height: 100%;
}


.navigationCheckbox:checked ~ .navigationBtn .navigationIcon {
  background-color: transparent;
}

.navigationCheckbox:checked+ .navigationBtn .navigationIcon::before {
  top: 0;
  transform: rotate(135deg);
}
.navigationCheckbox:checked+ .navigationBtn .navigationIcon::after {
  top: 0;
  transform: rotate(-135deg);
}