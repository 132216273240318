.view-carte {
    display: flex;
    position: relative;
    min-width: 350px;
    max-width: 400px;
    width: 70%;
    justify-content: center;
    font-size: 1.2em;
    align-self: flex-start;
    img {
      width: 100px;
      padding-bottom: 25px;
    }
    img:first-child {
      margin-right: auto;
    }
  
    .button {
      position: absolute;
      flex-wrap: wrap;
      align-self: flex-end;
    }
  }
  #show-menu{

    transition: all 3s ease-in;

  }
  .vitrine {
    // grid-area: 3 / 3 / 1 / 3;
    transform: rotate(6deg);
  }
  .img-vitrine {
    width: 100%;
    max-width: 550px;
    min-width: 400px;
    border: 0.2rem white solid;
    border-radius: $coin;
  }