.oignon {
  // grid-row-start: 2;
  max-height: 200px;
}

/* .nuggets{
      grid-row-start: 2;
  }
  .battonets{
      grid-row-start: 3;
  } */

// .platAll {
//   display: flex;
//   flex-direction: row;
//   grid-column: span 3;
//   margin: 0 auto;
//   grid-row-start: 2;
//   width: 1200px;
//   max-width: 1220px;
//   h1 {
//     font-size: 1.5em;
//   }
//   div {
//     // width: 100%;
//     border-right: $orange solid 2px;
//   }
//   div:nth-child(3) {
//     border-right: none;
//   }
// }

@media screen and (max-width: 450px) {
  .contents {
    margin: 25px;
    grid-gap: 20px;
    grid-template-columns: minmax(200px, 1fr);
  }
}
