/* Barre navigation */
.navHead {
  display: flex;
  max-height: 100px;
  padding: 2% 5% 2% 5%;
  justify-content: space-between;
  align-items: center;
  position: relative;
  border-bottom: 2px solid $dorange;
  z-index: 1001;
}


.title {
  color: whitesmoke;
  font-size: 4em;
  cursor: pointer;
  transition: all 0.2s;
  &:hover {
    color: rgb(220, 220, 220);
    transform: scale(1.05);
  }
}
.icon {
  display: inline-block;
  background-color: #fff;
  cursor: pointer;
}
@import "section-header/menu_hamburger";
/* Fin barre de navigation */
@import "button";
@import "section-header/nav-section-header";

.navigationBg,
.navigationBtn {
  display: none;
}

@media screen and (max-width: 678px) {
  .button-contact {
    display: none;
  }
  .navigationBg,
  .navigationBtn {
    display: block;
  }
  .navHead{
    background: linear-gradient(to right, rgb(0, 0, 0), rgb(36, 36, 36));
  }
}
